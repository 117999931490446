<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-order">
				<div class="morder-logistic" v-if="logisticInfo">
					<div class="logistic-head">
						<div class="item-label">{{logisticInfo.delivery_name?logisticInfo.delivery_name+' '+logisticInfo.logistic_code:logisticInfo.logistic_code}}</div>
						<div class="item-next" @click="handlePopShow">{{$t('mobile.actions.details')}}<i class="el-icon-arrow-right"></i></div>
					</div>
					<el-timeline v-if="logisticInfo && logisticInfo.trace.length>0" :reverse="true">
						<el-timeline-item
							v-for="(trace, index) in logisticInfo.trace.slice(logisticInfo.trace.length-3,logisticInfo.trace.length)"
							:key="index"
							:color="colorActive(index)"
							:timestamp="trace.AcceptTime">
							{{trace.AcceptStation}}
						</el-timeline-item>
					</el-timeline>
					<div v-else class="logistic-bottom">{{$t('mobile.empty.noLogistic')}}</div>
				</div>
				<div v-else class="morder-logistic">
					<div class="logistic-bottom" style="padding-bottom: 0;">{{$t('mobile.empty.noLogistic')}}</div>
				</div>
				<div class="morder-main">
					<div class="morder-head">
						<router-link v-if="orderInfo.seller_id"
							:to="{ name: 'mStoreInfo', params: { id: orderInfo.seller_id } }" class="name">
							{{ orderInfo.seller_name }}<i class="el-icon-arrow-right"></i></router-link>
					</div>
					<div class="morder-goods">
						<div class="item" v-for="(goods, index) in orderInfo.order_goods" :key="index">
							<router-link class="cell" :to="{ name: 'mGoodsInfo', params: { id: goods.goods_id } }">
								<img class="cell-pic" :src="goods.goods_image" alt="">
								<div class="cell-main">
									<div class="name">{{ goods.goods_name }}</div>
									<div class="desc">{{ goods.specification }}</div>
								</div>
								<div class="cell-box">
									<div class="price">￥<span>{{ goods.price }}</span></div>
									<div class="number">x{{ goods.quantity }}</div>
								</div>
							</router-link>
							<div class="actions" v-if="orderInfo.status > 11">
								<router-link v-if="goods.refund_status > 0"
									:to="{ name: 'mRefundInfo', params: { id: goods.apply_refund_info.apply_refund_id } }"
									class="btn">{{ goods.refund_status | statusFilter($t('mobile.filters.refundStatus')) }}</router-link>
								<router-link v-else
									:to="{ name: 'mOrderRefund', params: { order_id: orderInfo.order_id, goods_id: goods.goods_id } }"
									class="btn">{{ $t('mobile.actions.applyAfterSales') }}</router-link>
							</div>
						</div>
					</div>
					<div class="morder-info">
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.totalPrice') }}</div>
							<div class="item-block">{{ $t('mobile.order.totalPrice') }}<span class="price">￥<em>{{ orderInfo.goods_amount || '0.00' }}</em></span></div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.freight') }}</div>
							<div class="item-block">{{ $t('mobile.order.freight') }}({{ $t('mobile.order.express') }})<span class="price" v-if="addressInfo">￥<em>{{ addressInfo.shipping_fee || '0.00' }}</em></span></div>
						</div>
						<div class="item" v-if="orderInfo.coupon_id > 0">
							<div class="item-label">{{ $t('mobile.order.shopOffres') }}</div>
							<div class="item-block">{{ $t('mobile.order.shopOffres') }}<span class="price red">-￥<em>{{ orderInfo.discount || '0.00' }}</em></span></div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.actualPay') }}</div>
							<div class="item-block"><span class="price total">￥<em>{{ orderInfo.order_amount || '0.00' }}</em></span></div>
						</div>
						<div class="line"></div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.receiveInfo') }}</div>
							<div class="item-block" v-if="addressInfo">{{ addressInfo.consignee }}，{{ addressInfo.phone_tel || addressInfo.phone_mob }}，{{ addressInfo.region_name + addressInfo.address }}</div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.number') }}</div>
							<div class="item-block">{{ orderInfo.order_sn }}</div>
						</div>
						<template v-if="orderInfo.status > 11">
							<div class="item">
								<div class="item-label">{{ $t('mobile.order.payType') }}</div>
								<div class="item-block">{{ orderInfo.payment_name }}</div>
							</div>
							<div class="item">
								<div class="item-label">{{ $t('mobile.order.payNumber') }}</div>
								<div class="item-block">{{ orderInfo.out_trade_sn }}</div>
							</div>
						</template>
						<div class="item">
							<div class="item-label">{{ $t('mobile.order.createTime') }}</div>
							<div class="item-block">{{ orderInfo.add_time | parseTime() }}</div>
						</div>
						<div class="item" v-if="orderInfo.status > 11">
							<div class="item-label">{{ $t('mobile.order.payTime') }}</div>
							<div class="item-block">{{ orderInfo.pay_time | parseTime() }}</div>
						</div>
						<div class="item" v-if="orderInfo.status > 20">
							<div class="item-label">{{ $t('mobile.order.deliveryTime') }}</div>
							<div class="item-block">{{ orderInfo.ship_time | parseTime() }}</div>
						</div>
						<div class="item" v-if="orderInfo.status === 40">
							<div class="item-label">{{ $t('mobile.order.tradingTime') }}</div>
							<div class="item-block">{{ orderInfo.finished_time | parseTime() }}</div>
						</div>
					</div>
				</div>
				<div class="morder-block"></div>
			</div>
			
			<div class="mpop" :class="{'mpop-show':isPopShow}">
				<div class="mpop-bg" @click="handlePopHide"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopHide"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.actions.details')}}</div>
					<div class="mpop-main mpop-logistic">
						<el-timeline v-if="logisticInfo && logisticInfo.trace.length>0" :reverse="true">
							<el-timeline-item
								v-for="(trace, index) in logisticInfo.trace"
								:key="index"
								:timestamp="trace.AcceptTime">
								{{trace.AcceptStation}}
							</el-timeline-item>
						</el-timeline>
						<div v-else class="mpop-logistic-bottom">{{$t('mobile.empty.noLogistic')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchOrder, fetchLogistic } from '@/api/mobile/order'

export default {
	filters: {
		statusFilter(status, option) {
			return option[status]
		}
	},
	data() {
		return {
			orderInfo: {},
			addressInfo: null,
			logisticInfo: null,
			isPopShow: false
		}
	},
	created() {
		this.getOrderData()
	},
	methods: {
		colorActive(index) {
			if(index>1){
				return '#ff5000'
			}
		},
		async getOrderData() {
			let loading = this.$loading()
			try {
				const order = await fetchOrder({ order_id: this.$route.params.id })
				this.orderInfo = order.data
				this.addressInfo = order.data.order_extm
				const logistic = await fetchLogistic({ order_sn: this.orderInfo.order_sn,delivery_dist_id: this.$route.params.code })
				this.logisticInfo = logistic.data
			} catch(error) {
				this.$router.push({ name: 'mOrderList' })
			}
			loading.close()
		},
		handlePopShow() {
			this.isPopShow = true
		},
		handlePopHide() {
			this.isPopShow = false
		}
	}
}
</script>

<style lang="scss" scoped>
.mhome-order {
	padding: 0.384rem;

	.morder-logistic {
		padding: 0.384rem;
		margin-bottom: 0.256rem;
		border-radius: 0.256rem;
		background-color: #ffffff;
		.logistic-head {
			display: flex;
			height: 0.64rem;
			line-height: 0.64rem;
			justify-content: space-between;
			margin-bottom: 0.48rem;
			.item-label {
				font-size: 0.416rem;
				color: #666666;
			}
			.item-next {
				font-size: 0.384rem;
				color: #ff5000;
			}
		}
		::v-deep .el-timeline {
			.el-timeline-item {
				padding-bottom: 0.32rem;
				.el-timeline-item__wrapper {
					.el-timeline-item__content {
						font-size: 0.416rem;
						line-height: 0.608rem;
					}
					.el-timeline-item__timestamp {
						font-size: 0.384rem;
					}
				}
			}
		}
		.logistic-bottom {
			color: #999999;
			font-size: 0.384rem;
			text-align: center;
			padding-bottom: 0.32rem;
		}
	}

	.morder-main {
		border-radius: 0.256rem;
		background-color: #ffffff;

		.morder-head {
			display: flex;
			padding: 0.384rem;
			line-height: 0.544rem;
			justify-content: space-between;

			.name {
				color: #333333;
				font-size: 0.448rem;
				font-weight: bold;

				i {
					color: #999999;
				}
			}

			.type {
				color: #999999;
				font-size: 0.416rem;
			}
		}

		.morder-goods {
			padding: 0 0.384rem;

			.item {
				margin-bottom: 0.256rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.cell {
				display: flex;

				.cell-pic {
					flex-shrink: 0;
					width: 2.56rem;
					height: 2.56rem;
					border-radius: 0.32rem;
					object-fit: cover;
				}

				.cell-main {
					flex: 1;
					width: 0;
					margin: 0 0.32rem;

					.name {
						color: #333333;
						font-size: 0.416rem;
						height: 0.768rem;
						line-height: 0.768rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-weight: 600;
					}

					.desc {
						max-height: 1.088rem;
						line-height: 0.544rem;
						color: #999999;
						font-size: 0.416rem;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						white-space: normal;
					}
				}

				.cell-box {
					flex-shrink: 0;
					text-align: right;

					.price {
						height: 0.768rem;
						line-height: 0.768rem;
						color: #333333;
						font-weight: 600;
						font-size: 0.352rem;

						span {
							font-size: 0.448rem;
						}
					}

					.number {
						height: 0.544rem;
						line-height: 0.544rem;
						color: #999999;
						font-size: 0.384rem;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 0.256rem;

				.btn {
					color: #333333;
					font-size: 0.416rem;
					flex-shrink: 0;
					height: 0.544rem;
					line-height: 0.544rem;
					border: 1px solid #ddd;
					padding: 0.192rem 0.32rem;
					border-radius: 0.64rem;
					margin-left: 0.32rem;
				}

				.btn-red {
					color: #ff5000;
					border-color: #ff5000;
				}
			}
		}

		.morder-info {
			padding: 0.256rem 0.384rem;

			.item {
				display: flex;
				padding: 0.192rem 0;
				justify-content: space-between;

				.item-label {
					font-size: 0.416rem;
				}

				.item-block {
					max-width: 70%;
					color: #999999;
					font-size: 0.416rem;
					line-height: 0.576rem;
					text-align: right;

					.price {
						color: #333333;
						font-size: 0.384rem;
						margin-left: 0.32rem;
						font-weight: 600;

						em {
							font-size: 0.448rem;
						}

						&.red {
							color: #ff5000;
						}

						&.total {
							color: #ff5000;
							font-size: 0.416rem;

							em {
								font-size: 0.48rem;
							}
						}
					}
				}
			}

			.line {
				border-top: 1px solid #e5e5e5;
				margin: 0.128rem 0;
			}
		}
	}
}
.mpop-logistic {
	position: relative;
	height: 8.64rem;
	padding: 0.32rem 0.384rem;
	overflow-y: auto;
	::v-deep .el-timeline {
		.el-timeline-item {
			padding-bottom: 0.32rem;
			.el-timeline-item__wrapper {
				.el-timeline-item__content {
					font-size: 0.416rem;
					line-height: 0.608rem;
				}
				.el-timeline-item__timestamp {
					font-size: 0.384rem;
				}
			}
		}
	}
	.mpop-logistic-bottom {
		top: 40%;
		position: relative;
		color: #999999;
		font-size: 0.384rem;
		text-align: center;
	}
}
</style>